<template>
    <div>
        <h1 class="text-center display-3 py-md-5">Lataa dokumentteja</h1>
        <v-alert v-if="!loading && files.length === 0" type="info" prominent>
            Pääkäyttäjä ei ole vielä lisännyt dokumentteja
        </v-alert>
        <FileList v-show="files.length" :files="files" />
    </div>
</template>

<script>
    import FileList from "@/components/FileList";
    export default {
        components: { FileList },
        data() {
            return {
                loading: false,
                files: []
            };
        },

        methods: {
            fetchFiles() {
                this.loading = true;
                this.$http
                    .get("/api/documents")
                    .then(response => (this.files = response.data))
                    .then(() => (this.loading = false));
            }
        },

        created() {
            this.fetchFiles();
        }
    };
</script>

<style scoped>
</style>